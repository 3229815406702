var appPuzzle = document.getElementById('app');
var Puzzle = document.getElementsByTagName("canvas");
var containerReference = document.getElementById("container-reference");
var containerFrame = document.getElementById('container-frame');
var containerPuzzle = document.getElementById('container-layer');
// console.log(containerFrame);
document.addEventListener("DOMContentLoaded", function () {
    coreFunctions.init({
        copyright: ['copyright', 'de'], //Container, Language
        fbPixel: 'four',    //"aor","columbia","family","klassik","spass","catalog","four","international","filtr", "at", "ch"
        analytics: {
            'gaAccount': 'XXX'
        },
    });
});

document.addEventListener("DOMContentLoaded", function () {
// Create a new SMEAppleMusic object with your parameters.
    var sm = new SMEAppleMusic({
        ae: {
            ae: "519452a447eb9041d9c36f2c39d411d9f8a2f6f5acf47be68195a347f7032abe",
            brand_id: "3453880",
            segment_id: "1727454",
            activities: '{"actions":{"presave":99693}}'
        },
        am: {
            dev_token: "",
            save_mode: "library",
            custom_playlist_name: "",
            new_apple_presave: true,
            resources: {
                playlists: [],
                albums: []
            }
        },
        sf: {
            form: "225591",
            default_mailing_list: ""
        },
        smf: {
            campaign_id: "225591",
            campaign_key: "e80d123d7b71daf37a1fc49b47020250"
        }
    });
    document.getElementById("apple_music_form").addEventListener("submit", function (e) {
        e.preventDefault();
// Grab the email address value.
        var email = document.getElementById("apple_music_email_address").value;
// Pass the email address and opt in status
// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
        sm.doActions(email, false).then(function (res) {
            window.location = './?state=success';
        }).catch(function (err) {

        });
    });
});

let isItlocked = true;
let aeJS = null;

function AEJSLoginHandler(user, type) {


    console.log(user)


}

function AEJSReady(_aeJS) {
    aeJS = _aeJS;
    aeJS.settings.auth_window = true;
    aeJS.settings.refresh_demographics = true;
    aeJS.settings.services = 'email,spotify,facebook,twitter,instagram,deezer,google,youtube';
    aeJS.settings.extra_fields_screen = 'disabled';
    aeJS.events.onLogin.addHandler(AEJSLoginHandler);
}

window.onload = (event) => {
    let SMEPreSave = {
        state: null,
        spotify_user: null,
        url_vars: [],
        getURLVars: function (callback, form_id) {
            let hash;
            let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (let i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                this.url_vars.push(hash[0]);
                this.url_vars[hash[0]] = hash[1];
            }
            this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
            this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
            this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
            this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
            this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
            if (typeof callback === 'function') {
                callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
            }
        }
    };
    /* String state
     *  The status of the pre-save.
     *  Int ae_member_id
     *  The Appreciation Engine member ID. This can be passed to the forms processor.
     *  String spotify_user
     *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
     *  String deezery_user
     *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
     */
    SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {

        // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
        if (state === 'success' || state === 'thank-you') {
            document.getElementById("presave-hero").style.display = "none";
            isItlocked = false;
        }
    });
}
